const vi = {
  network: {
    tips: `Mạng lựa chọn hiện không được hỗ trợ!
      Vui lòng chọn mạng Matic (Polygon)!`,
    ev: "Vui lòng kiểm tra liệu bạn đã cài đặt phần mềm ví như MetaMask, Trust Wallet, imToken, hay TokenPocket chưa.",
    copyight: "FlashMonkey",
    all: "Tối đa",
    bolck: "Đồng tiền ổn định Dai",
    law: `Thông báo cho nhà đầu tư: Ứng dụng phân quyền (DApp) này mang rủi ro đầu tư không chắc chắn. Các chính sách quốc gia và luật pháp kiểm soát và hạn chế công nghệ blockchain và các khoản đầu tư liên quan. Ngoài ra, giá của các loại tiền số biến động mạnh, có thể khiến nhà đầu tư phải chịu rủi ro đầu tư lớn. Trước khi tham gia đầu tư, nhà đầu tư nên hiểu rõ và đánh giá rủi ro đầu tư, và ra quyết định một cách cẩn thận. Chúng tôi khuyên nhà đầu tư nên đầu tư một cách có lý và tránh theo đuổi đám đông một cách mù quáng, nguy cơ mất mát tài chính không thể đảo ngược.`,
    protocol: `Ứng dụng phân quyền (DApp) này mang rủi ro đầu tư không chắc chắn. Trước khi sử dụng, hãy đảm bảo bạn đã đọc và hiểu rõ cảnh báo rủi ro và chính sách bảo mật.`,
    more: "Thêm",
    media: "Truyền thông xã hội liên quan",
    stake:
      "Mở cửa toàn cầu vào ngày 6 tháng 11 năm 2023, hãy theo dõi chúng tôi trên Telegram và Twitter!",
    withdraw:
      "Mở cửa toàn cầu vào ngày 6 tháng 11 năm 2023, hãy theo dõi chúng tôi trên Telegram và Twitter!",
    Reinvest:
      "Mở cửa toàn cầu vào ngày 6 tháng 11 năm 2023, hãy theo dõi chúng tôi trên Telegram và Twitter!",
    Getreward:
      "Mở cửa toàn cầu vào ngày 6 tháng 11 năm 2023, hãy theo dõi chúng tôi trên Telegram và Twitter!",
    linkwallet: "Kết nối ví",
    notification:
      "FlashMonkey 2023/10/20 20:40 Bắt đầu Kiểm thử Công cộng Toàn cầu",
  },
  leavel: {
    1: "Quảlýchuađen",
    2: "Quảanhđào",
    3: "Thanhlong",
    4: "Hạtphỉ",
    5: "Noni",
    6: "Hồng",
    7: "Nấmtruffle",
  },
  top: {
    Account: "Tài khoản",
  },
  stake: {
    Balance: "Số dư của bạn",
    Total: "Tổng cược",
    Appoove: "Chấp nhận",
    Stake: "Cược",
  },
  menu: {
    Home: "Trang chủ",
    Language: "Ngôn ngữ",
    Document: "Tài liệu",
    Contract: "Hợp đồng",
    Watch: "Theo dõi",
    Coming: "Sắp ra mắt",
    EvDao: "FM-DAO",
  },
  Withdraw: {
    Withdraw: "Rút tiền",
    Withdrawable: "Có thể rút",
    Earnings: "Lợi nhuận",
    Brokerage: "Số tiền môi giới",
    Reinvest: "Tái đầu tư",
    Getreward: "Nhận phần thưởng",
    Processing: "Đang xử lý",
    unsuccessfule: "Không thành công",
    successful: "Thành công!",
  },
  Team: {
    innver: "Nội tại",
    Teamstaked: "Đội cược",
    leavel: "Cấp độ",
    Team: "Đội",
    Bind: "Gắn kết mối quan hệ",
    info: "Hệ thống tạm thời tạm ngừng kết nối, vui lòng đợi để mở lại.",
  },
  Share: {
    Invitelink: "Liên kết mời",
    Intips: `Lời mời Dapp hiện không khả dụng. 
              Vui lòng chờ cập nhật mới nhất từ cộng đồng.`,
    copyTitle: "Liên kết mời",
    copyText: "Liên kết mời đã được sao chép thành công!",
    copyCommnad: "Sao chép liên kết",
  },
  Contract: {
    contractaddress: "Địa chỉ hợp đồng",
    Copylink: "Polygonscan",
    CopylinkText: "Chuyển đến polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Ví chỉ xem",
    addwallet: "Thêm ví",
    add: "Thêm",
    Leavel: "Cấp độ",
    share: "Chia sẻ",
    fnOpen: "Ví này chưa được mời tham gia tính năng này!",
    nullString: "Vui lòng nhập địa chỉ ví Polygon(matic) hợp lệ!",
  },
};
export default vi;
