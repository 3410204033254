const pt = {
  network: {
    tips: `A rede selecionada não é suportada atualmente!
    Por favor, selecione a rede Matic (Polygon)!`,
    ev: `Por favor, verifique se instalou algum software de carteira, como MetaMask, 
    Trust Wallet, imToken ou TokenPocket.`,
    copyight: "FlashMonkey",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `Aviso aos Investidores: Este Aplicativo Distribuído (DApp) apresenta riscos de investimento incertos. Políticas nacionais e regulamentações podem exercer controle e limitações estritas sobre a tecnologia blockchain e investimentos relacionados. Adicionalmente, o preço das moedas digitais é extremamente volátil, podendo fazer com que os investidores enfrentem riscos significativos de investimento. Antes de participar de qualquer investimento, os investidores devem entender e avaliar completamente os riscos envolvidos e tomar decisões de maneira cautelosa. Fortemente aconselhamos os investidores a investirem racionalmente e a evitar seguir tendências cegamente, prevenindo assim perdas financeiras irreversíveis.`,
    protocol: `Este Aplicativo Distribuído (DApp) apresenta riscos de investimento incertos. Antes de usar, por favor, leia atentamente e compreenda totalmente os avisos de risco e a política de privacidade.`,
    more: "Detalhes",
    media: "Mídias Sociais Relacionadas",
    stake:
      "Abertura global em 6 de novembro de 2023, siga-nos no Telegram e Twitter!",
    withdraw:
      "Abertura global em 6 de novembro de 2023, siga-nos no Telegram e Twitter!",
    Reinvest:
      "Abertura global em 6 de novembro de 2023, siga-nos no Telegram e Twitter!",
    Getreward:
      "Abertura global em 6 de novembro de 2023, siga-nos no Telegram e Twitter!",
    linkwallet: " Conectar Carteira",
    notification: "FlashMonkey 2023/10/20 20:40 Início do Teste Público Global",
  },
  leavel: {
    1: "Groselha-preta",
    2: "Cerejas",
    3: "Pitaia",
    4: "Avelã",
    5: "Noni",
    6: "Caqui",
    7: "Trufa",
  },
  top: {
    Account: "Conta",
  },
  stake: {
    Balance: "Seu saldo",
    Total: "Aposta total",
    Appoove: "Aprovar",
    Stake: "Aposta",
  },
  menu: {
    Home: "Início",
    Language: "Idioma",
    Document: "Documento",
    Contract: "Contrato",
    Watch: "Assistir",
    Coming: "Em breve",
    EvDao: "FM-DAO",
  },
  Withdraw: {
    Withdraw: "Retirar",
    Withdrawable: "Retirável",
    Earnings: "Ganhos",
    Brokerage: "Quantidade de corretagem",
    Reinvest: "Reinvestir",
    Getreward: "Obter recompensa",
    Processing: "Em processamento",
    unsuccessfule: "Não foi bem-sucedido",
    successful: "Bem-sucedido!",
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Aposta da equipe",
    leavel: "Nível",
    Team: "Equipe",
    Bind: "Vincular relação",
    info: "O sistema pausou temporariamente a vinculação, por favor aguarde a abertura.",
  },
  Share: {
    Invitelink: "Link de convite",
    Intips:
      "Os convites Dapp não estão disponíveis no momento. Aguarde as atualizações mais recentes da comunidade.",
    copyTitle: "Link de convite",
    copyText: "Link de convite copiado com sucesso!",
  },
  Contract: {
    contractaddress: "Endereço do contrato",
    Copylink: "Polygonscan",
    CopylinkText: "Ir para polygonscan!",
    copyCommnad: "Copiar Link",
  },
  Wathch: {
    watchonlywallet: "Carteira somente para assistir",
    addwallet: "Adicionar",
    add: "Adicionar",
    Leavel: "Nível",
    share: "Compartilhar",
    fnOpen:
      "Esta carteira ainda não foi convidada para participar desta funcionalidade!",
    nullString:
      "Por favor, insira um endereço de carteira Polygon(matic) válido!",
  },
};
export default pt;
