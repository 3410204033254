const zhTW = {
  network: {
    tips: `目前不支援所選的網路！請選擇 Matic (Polygon) 網路！`,
    ev: `請檢測是否安裝 \nMetaMask,\nTrustWallet,imToken,TokenPocket\n等錢包中任意一款錢包軟體.`,
    copyight: "FlashMonkey",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `投資者須知：本分佈式應用程序（DApp）存在不確定的投資風險。國家政策和監管法規對於區塊鏈技術和相關投資可能有嚴格的控制和限制。此外，數字貨幣的價格極易波動，可能導致投資者承受巨大的投資風險。投資者在參與投資之前，
    應充分了解和評估投資的風險，並謹慎做出決策。我們強烈建議投資者理性投資，
    避免盲目跟風，以免造成不可挽回的經濟損失。`,
    protocol: `本分佈式應用程序（DApp）潛藏不確定的投資風險。在使用前，請您務必細致閱讀並充分理解風險提示及隱私條款。`,
    more: "詳情",
    media: "相關社交媒體",
    stake: "2023年11月6日全球開放，請關注Telegram、Twitter!",
    withdraw: "2023年11月6日全球開放，請關注Telegram、Twitter!",
    Reinvest: "2023年11月6日全球開放，請關注Telegram、Twitter!",
    Getreward: "2023年11月6日全球開放，請關注Telegram、Twitter!",
    linkwallet: "连接钱包",
    notification: "FlashMonkey 2023/10/20 20:40 全球公測開始!",
  },
  leavel: {
    1: "黑加侖",
    2: "车厘子",
    3: "火龍果",
    4: "榛子",
    5: "諾麗果",
    6: "牛心柿",
    7: "松露",
  },
  top: {
    Account: "帳戶",
  },
  stake: {
    Balance: "錢包",
    Total: "己經存入",
    Appoove: "授權",
    Stake: "存入",
  },
  menu: {
    Home: "首頁",
    Language: "語言",
    Document: "檔案",
    Contract: "合約",
    Watch: "觀察",
    Coming: "即將推出",
    EvDao: "FM-DAO",
  },
  Withdraw: {
    Withdraw: "提取",
    Withdrawable: "可提取",
    Earnings: "個人收益",
    Brokerage: "生態收益",
    Reinvest: "再資",
    Getreward: "獲取獎勵",
    Processing: "處理中",
    unsuccessfule: "失敗",
    successful: "成功！",
  },
  Team: {
    innver: "邀請",
    Teamstaked: "生態",
    leavel: "榮譽",
    Team: "生態",
    Bind: "綁定關係",
    info: "系統暫停綁定，請等待開放！",
  },
  Share: {
    Invitelink: "邀請鏈接",
    Intips: "Dapp邀請目前不可用。請等待最新社區更新。",
    copyTitle: "邀請鏈接",
    copyText: "邀請鏈接複製成功！",
    copyCommnad: "複製連結",
  },
  Contract: {
    contractaddress: "合約地址",
    Copylink: "Polygonscan",
    CopylinkText: "前往polygonscan",
  },
  Wathch: {
    watchonlywallet: "僅觀看錢包",
    addwallet: "添加觀察錢包",
    add: "添加",
    Leavel: "榮譽",
    share: "分享",
    fnOpen: "暫未邀請此錢包參與此功能！",
    nullString: "請輸入合法的Polygon(matic)錢包地址！",
  },
};
export default zhTW;
