const ru = {
  network: {
    tips: `Выбранная сеть в настоящее время не поддерживается!
      Пожалуйста, выберите сеть Matic (Polygon)!`,
    ev: `Пожалуйста, проверьте, установлен ли у вас кошелек, такой как MetaMask,
     Trust Wallet, imToken, или TokenPocket.`,
    copyight: "FlashMonkey",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `Уведомление для инвесторов: Это децентрализованное приложение (DApp) несет в 
    себе неопределенные инвестиционные риски. Национальные политики и регулятивные законы 
    могут строго контролировать и ограничивать технологии блокчейна и сопутствующие 
    инвестиции. Кроме того, цены на цифровые валюты сильно колеблются, что может привести 
    к существенным инвестиционным рискам для инвесторов. Прежде чем принимать участие в 
    инвестициях, инвесторы должны полностью понимать и оценивать риски, связанные с 
    инвестициями, и принимать решения осмотрительно. Мы настоятельно рекомендуем инвесторам 
    инвестировать рационально и избегать слепого следования за массами, чтобы предотвратить 
    необратимые финансовые потери.`,
    protocol: `Это распределенное приложение (DApp) имеет неопределенные инвестиционные риски. Перед использованием, пожалуйста, внимательно прочитайте и полностью поймите предупреждения о рисках и политику конфиденциальности.`,
    more: "подробности",
    media: "соответствующие социальные сети",
    stake:
      "Глобальное открытие 6 ноября 2023 года, подписывайтесь на нас в Telegram и Twitter!",
    withdraw:
      "Глобальное открытие 6 ноября 2023 года, подписывайтесь на нас в Telegram и Twitter!",
    Reinvest:
      "Глобальное открытие 6 ноября 2023 года, подписывайтесь на нас в Telegram и Twitter!",
    Getreward:
      "Глобальное открытие 6 ноября 2023 года, подписывайтесь на нас в Telegram и Twitter!",
    linkwallet: "Подключить кошелек",
    notification:
      "FlashMonkey 2023/10/20 20:40 Начало глобального публичного тестирования",
  },
  leavel: {
    1: "Чёрная смородина",
    2: "Вишня",
    3: "Драконий фрукт",
    4: "Лесной орех",
    5: "Нони",
    6: "Хурма",
    7: "Трюфель",
  },
  top: {
    Account: "Аккаунт",
  },
  stake: {
    Balance: "Ваш Баланс",
    Total: "Общая Ставка",
    Appoove: "Утвердить",
    Stake: "Ставка",
    info: "Система временно приостановила привязку, пожалуйста, подождите, пока она не откроется.",
  },
  menu: {
    Home: "Главная",
    Language: "Язык",
    Document: "Документ",
    Contract: "Контракт",
    Watch: "Наблюдение",
    Coming: "Скоро",
    EvDao: "FM-DAO",
  },
  Withdraw: {
    Withdraw: "Вывести",
    Withdrawable: "Доступно для вывода",
    Earnings: "Доход",
    Brokerage: "Сумма брокерского вознаграждения",
    Reinvest: "Реинвестировать",
    Getreward: "Получить награду",
    Processing: "Обработка",
    unsuccessfule: "Неуспешно",
    successful: "Успешно!",
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Ставки команды",
    leavel: "Уровень",
    Team: "Команда",
    Bind: "Привязать отношения",
  },
  Share: {
    Invitelink: "Ссылка для приглашения",
    Intips:
      "Приглашения в Dapp в настоящее время недоступны. Пожалуйста, ждите последних обновлений от сообщества.",
    copyTitle: "Ссылка для приглашения",
    copyText: "Ссылка для приглашения успешно скопирована!",
    copyCommnad: "Скопировать ссылку",
  },
  Contract: {
    contractaddress: "Адрес контракта",
    Copylink: "Polygonscan",
    CopylinkText: "Перейти к polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Кошелек только для наблюдения",
    addwallet: "Добавить",
    add: "Добавить",
    Leavel: "Уровень",
    share: "Поделиться",
    fnOpen: "Этот кошелек еще не был приглашен участвовать в этой функции!",
    nullString:
      "Пожалуйста, введите действительный адрес кошелька Polygon(matic)!",
  },
};
export default ru;
