<template>
  <div class="stakewait">
    <!-- <div class="marquee">
      <Marquee :duration="15">{{ $t("network.notification") }}</Marquee>
    </div> -->
    <div class="coinInfomation">
      <div class="coinImg"><img :src="dai" class="dailogo" /></div>
      <div class="coinInfo">{{ $t("network.bolck") }}</div>
    </div>
    <Divider style="background: rgba(142, 200, 243, 0.1)" />
    <div class="ownwalletInfo">
      <div class="ownTips">{{ $t("stake.Balance") }}:</div>
      <div class="ownMoney">
        {{ formattedDaiOwnMoney }}<img class="daimi" :src="daimni" />
      </div>
    </div>
    <div class="inputInfo">
      <input v-model.number="daiValue" type="number" class="inputStyle" @input="validateAmount"/>
      <button @click="daiValue = formattedDaiOwnMoney,validateAmount()" class="tbnStyle">
        {{ $t("network.all") }}
      </button>
    </div>
    <div class="StakeInfo">
      <div class="StakeResult">{{ $t("stake.Total") }}:</div>
      <div class="StakeMoney">
        {{ formattedDaiOwnMoney2 }}<img class="daimi" :src="daimni" />
      </div>
    </div>
    <hr class="hr1" />
    <div class="tvl"><span>TVL</span>:{{needinvest}}M</div>
    <div class="currP">
      <ProgressBar :progress="currentProgress"></ProgressBar>
    </div>
    <div class="StakeAuthor">
      <div class="Author">
       <button :disabled="empowerbutton" class="StakeBtn" @click="empower" v-if="typeApprove==0">  <!-- :disabled="isButtonDisabled" -->
          {{ $t("stake.Appoove") }}
        </button>
        <button  class="StakeBtn" v-if="typeApprove==1">
          授权中
        </button>
      </div>
      <div class="Stakeing">
        <button :disabled="depositDisabled" class="StakeBtn" @click="stake" v-if="typedeposit==0">{{ $t("stake.Stake") }}</button>
        <button  class="StakeBtn" v-else>
          存入中 
        </button>
        
      </div>
    </div>

    <div><img class="bStyle" :src="stylea" alt="" /></div>
  </div>
</template>

<style lang="less" scoped>
.stakewait {
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0.4px rgb(80, 77, 77) inset;
  background-image: linear-gradient(
    133deg,
    rgba(7, 110, 150, 0.3),
    /* Adjust the last value (0.2) to set the opacity of the first color */
      rgba(132, 2, 72, 0.6)
  );
  /* Adjust the last value (0.2) to set the opacity of the second color */
  box-sizing: border-box;
  width: 330px;
  color: white;
  padding: 40px 20px 20px 20px;

  .bStyle {
    width: 290px;
    height: 76px;
  }
  .currP {
    margin-bottom: 20px;
  }

  .StakeAuthor {
    display: flex;
    justify-content: space-between;

    .StakeBtn {
      width: 136px;
      height: 43px;
      border-radius: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 18px;
      color: rgb(2, 0, 0);
      font-size: 20px;
      background-image: linear-gradient(
        126deg,
        rgba(142, 200, 243, 0.3),
        /* Adjust the last value (0.2) to set the opacity of the first color */
          rgba(250, 51, 158, 0.3)
      );
    }

    .Author {
      width: 136px;
      height: 43px;
    }

    .Stakeing {
      width: 136px;
      height: 43px;
    }
  }
  .hr1 {
    border: none;
    border-top: 1px solid rgba(142, 200, 243, 0.3); /* 蓝色线条 */
  }
  .tvl {
    font-size: 10px;
    margin: 5px 0 5px 0;
    span {
      font-weight: 800;
      color: rgba(255, 255, 255, 0.4);
    }
  }
  .StakeInfo {
    display: flex;
    margin-bottom: 10px;

    .StakeResult {
      flex: 1;
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      top: 6px;
    }

    .StakeMoney {
      text-align: right;
      font-size: 20px;
      font-weight: 600;

      .daimi {
        margin-left: 5px;
        width: 14px;
        height: 12px;
      }
    }
  }

  .inputInfo {
    border: 3px solid #81c0f1;
    height: 53px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 10px 0;
    display: flex;

    .inputStyle {
      border: none;
      outline: none;
      width: 220px;
      height: 100%;
      font-size: 20px;
      padding: 0 10px;
      color: #81c0f1;
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .tbnStyle {
      flex: 1;
      color: rgb(72, 2, 64);
      border: none;
      outline: none;
      font-size: 20px;
      border-radius: 0px 5px 5px 0px;
      cursor: pointer;
      background: url("../assets/img/Gradient.svg") no-repeat center
        center/cover;
    }
  }

  .ownwalletInfo {
    font-family: "Poppins", sans-serif;
    display: flex;

    .ownTips {
      font-size: 18px;
      font-weight: 600;
      flex: 1;
    }

    .ownMoney {
      font-size: 20px;

      img.daimi {
        width: 14px;
        height: 12px;
        margin-left: 5px;
      }
    }
  }
  .marquee {
    margin-bottom: 15px;
    color: #81c0f1;
  }
  .coinInfomation {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .coinImg {
      width: 29px;
      height: 29px;

      .dailogo {
        width: 29px;
        height: 29px;
      }
    }

    .coinInfo {
      font-size: 18px;
      font-family: "Poppins", sans-serif;
      position: relative;
      top: 0px;
      left: 10px;
      font-weight: bold;
    }
  }
}
</style>
<script>
import ProgressBar from "@/components/ProgressBar.vue";
//import Marquee from "@/components/Marquee.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import siteapi from "@/json/siteapi.json";
import Plugens from "@/plugins/index";
export default {
  data() {
    return {
      isButtonDisabled: true,
      depositDisabled:true,
      typeApprove:0,
      typedeposit:0,
      dai: dai,
      daimni: daimni,
      daiValue: 0,
      daiOwnMoney: 0,
      stylea: stylea,
      stakedMoney: 0,
      currentProgress: 0,
      usersdata:{},
      sitedata:{},
      addresscode:"",//当前用户地址
      empowerbutton:true,
      needinvest:"",
      Minimal:0
    };
  },
  components: {
    //Marquee,
    ProgressBar,
  },
  async mounted() {
      // 判断是否连接钱包
if (typeof window.ethereum !== 'undefined') {
  console.log("已连接钱包");

  // 异步获取网络信息
  window.ethereum
    .request({ method: 'eth_chainId' })
    .then((chainId) => {
      // 判断当前网络是否为Polygon链
      if (chainId === '0x89') {
        console.log("当前网络为Polygon链");
        // this.$router.push({path:'/EvStake'});
      } else {
        console.log("当前网络不是Polygon链");
        this.$router.push({path:'/'});
        return
      }
    })
    .catch((error) => {
      console.error('获取网络信息失败:', error);
    });
} else {
  console.log("未连接钱包");
}
this.sitedata=siteapi
    const currentAddress = await web3Utils.connectWallet();
    console.log(currentAddress)
    this.addresscode=currentAddress
    if (currentAddress) {
      this.daiOwnMoney = parseFloat(
        await web3Utils.getDaiBalance(this.addresscode)
      );
     
      console.log(this.daiOwnMoney.toFixed(6));
      this.users()
      this.exchangeallowance()
      this.limitAllInvestAmount()
      this.minInvestAmount()
      // this.exchangeallowance()
    }
  },
  methods: {
    async getdata(){
      const currentAddress = await web3Utils.connectWallet();
    console.log(currentAddress)
    this.addresscode=currentAddress
    if (currentAddress) {
     
      this.daiOwnMoney = parseFloat(
        await web3Utils.getDaiBalance(this.addresscode)
      );
     
      console.log(this.daiOwnMoney.toFixed(6));
      this.users()
      this.limitAllInvestAmount()
      this.minInvestAmount()
      // this.exchangeallowance()
    }
    },
    // input输入数量
    validateAmount(){
      console.log(this.daiValue)
      console.log(this.daiValue*1<this.Minimal)
      if(this.daiValue*1<this.Minimal){
        this.isButtonDisabled=true
        this.depositDisabled=true
      }else{
        this.isButtonDisabled=false
        this.depositDisabled=false
        this.exchangeallowance()
      }
    },
    // 授权按钮
    empower(){
      // if(this.daiValue<10){
      //   console.log("不能小于100");
      //   this.$Notice.warning({
      //   title: "FlashMonkey",
      //   desc:  '不能小于100',
      // });
      //   return
      // }
      this.Approve();
      // this.estimateGas()
      
    },
   
     // 合约授权
  async Approve() {
    console.log("授权")
    let _decimals = 18;
    let Address;
    let spender;
    Address = this.sitedata.dai_token.address;
      spender =this.sitedata.aave_token.address;
      this.typeApprove = 1;
    try {
      await Plugens.approveTokens(
        Address, //地址
        this.daiValue, //数量
        this.addresscode,
        spender,
        _decimals,
        
      ).then((res) => {
        console.log(res);
        this.typeApprove = 0;
        if (res.code == 0) {
          this.typeApprove = 0;
          this.$Notice.success({
            title: "提示",
            desc:  '授权成功',
          });
          this.validateAmount()
        } else {
          // this.typeApprove = 1;
          this.$Notice.warning({
            title: "FlashMonkey",
            desc:  '授权失败',
          });
        }

      });
    } catch (error) {
      // this.typeApprove = 1;
      this.$Notice.warning({
        title: "FlashMonkey",
        desc:  '授权失败',
      });
      console.log(error);
    }
  },
      // 查看合约授权数量
  async exchangeallowance() {
    let _decimals = 18;
    let spender
    let Address
      Address = this.sitedata.dai_token.address;
      spender =this.sitedata.aave_token.address;
      await Plugens.allowancetoken(
        Address, //地址
        0, //数量
        this.addresscode,
        spender,
        _decimals
      ).then((result) => {
        console.log(result,'是否授权');
       
        if(result.data*1>=10000){
          this.empowerbutton=true
        }else{
          this.empowerbutton=false
        }
        if(this.daiValue<=result.data*1){
          this.depositDisabled=false
        }else{
          this.depositDisabled=true
        }
       
      });
    },
         // 合约存入
    async deposit() {
    console.log("合约存入")
    let _decimals = 18;
    let Address;
    Address = this.sitedata.aave_token.address;
      this.typedeposit = 1;
    try {
      await contract.deposit(
        Address, //合约地址
        this.daiValue, //数量
        this.addresscode,//当前地址
        _decimals,//小数
        
      ).then((res) => {
        console.log(res);
        this.typedeposit = 0;
        if (res.code == 0) {
          this.typedeposit = 0;
          this.$Notice.success({
            title: "提示",
            desc:  '存入成功',
          });
          this.users()
          this.limitAllInvestAmount()
      this.minInvestAmount()
          this.getdata()
        } else {
          // this.typeApprove = 1;
          this.$Notice.warning({
            title: "FlashMonkey",
            desc:  '存入失败',
          });
        }

      });
    } catch (error) {
      // this.typeApprove = 1;
      this.$Notice.warning({
        title: "FlashMonkey",
        desc:  '存入失败',
      });
      console.log(error);
    }
  },
  
  // 需要投资
  async limitAllInvestAmount(){
    console.log(this.sitedata)
    let _decimals = 18;
    let ContractAddress;
    console.log('需要投资')
    ContractAddress = this.sitedata.aave_token.address;
      await contract.limitAllInvestAmount(
        this.addresscode,  //当前用户地址
        ContractAddress,  //调用合约
        _decimals
      ).then((res) => {
        console.log(res,'需要投资');
        this.needinvest=res.data*1/100000000
       this.allInvestAmount(res.data)
      });
    },
      // 已投资
  async allInvestAmount(value){
    console.log(this.sitedata)
    let _decimals = 18;
    let ContractAddress;
    console.log('已投资')
    ContractAddress = this.sitedata.aave_token.address;
      await contract.allInvestAmount(
        this.addresscode,  //当前用户地址
        ContractAddress,  //调用合约
        _decimals
      ).then((res) => {
        console.log(res,'已投资');
        const total = value*1;
        console.log(total)
        this.currentProgress = (res.data*1 / total) * 100;
        this.currentProgress=this.currentProgress.toFixed(2)*1;
      });
    },
      // 最小投入总数
  async minInvestAmount(){
    console.log(this.sitedata)
    let _decimals = 18;
    let ContractAddress;
    console.log('最小投入总数')
    ContractAddress = this.sitedata.aave_token.address;
      await contract.minInvestAmount(
        this.addresscode,  //当前用户地址
        ContractAddress,  //调用合约
        _decimals
      ).then((res) => {
        console.log(res,'最小投入总数');
        this.Minimal=res.data
      });
    },
      // 用户信息
  async users(){
    console.log(this.sitedata)
    let _decimals = 18;
    let ContractAddress;
    console.log('用户信息')
    ContractAddress = this.sitedata.aave_token.address;
      await contract.users(
        this.addresscode,  //当前用户地址
        ContractAddress,  //调用合约
        _decimals
      ).then((res) => {
        console.log(res,'用户信息');
        this.usersdata=res.data
        this.stakedMoney=res.data.investAmount*1
        console.log(this.stakedMoney)
        // const total = 14000;
        // this.currentProgress = (this.stakedMoney / total) * 100;
      });
    },
    // 存入按钮
    stake() {
      console.log(this.daiValue)
      if(this.daiValue*1<this.Minimal){
        console.log("不能小于"+this.Minimal);
        this.$Notice.warning({
        title: "FlashMonkey",
        desc:  '不能小于'+this.Minimal,
      });
      
        return
      }
      this.deposit()
      // this.warning(false);
    },
    warning(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.stake"),
      });
    },
  },
  computed: {
    formattedDaiOwnMoney() {
      if (!this.daiOwnMoney) return "0.0000";
      const multipliedNumber = this.daiOwnMoney * 100000;
const truncatedNumber = Math.trunc(multipliedNumber);
const result = truncatedNumber / 100000;
console.log(result)
console.log(this.daiOwnMoney)
      let value = parseFloat(result);
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 5,
        maximumFractionDigits: 5,
      });
    },
    formattedDaiOwnMoney2() {
      if (!this.stakedMoney) return "0.0000";
      const multipliedNumber = this.stakedMoney * 100000;
const truncatedNumber = Math.trunc(multipliedNumber);
const result = truncatedNumber / 100000;
console.log(result)
console.log(this.stakedMoney)
      let value = parseFloat(result);
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 5,
        maximumFractionDigits: 5,
      });
    },
  },
};
</script>
