<template>
  <div class="stakewait">
    <div class="coinInfomation">
      <div class="coinImg"><img :src="dai" class="dailogo" /></div>
      <div class="coinInfo">{{ $t("network.bolck") }}</div>
    </div>
    <Divider style="background: rgba(142, 200, 243, 0.1)" />
    <div class="ownwalletInfo">
      <div class="ownTips">{{ $t("Withdraw.Withdrawable") }}:</div>
      <div class="ownMoney">
        {{ formattedDaiOwnMoney }}<img class="daimi" :src="daimni" />
      </div>
    </div>
    <div class="inputInfo">
      <input v-model.number="daiValue" type="number" class="inputStyle" />
      <button @click="daiValue = formattedDaiOwnMoney" class="tbnStyle">
        {{ $t("network.all") }}
      </button>
    </div>
    <div class="WithDrawBtn">
      <button class="tbnStyle2" @click="withdraw" v-if="typeextract==0">
        {{ $t("Withdraw.Withdraw") }}
      </button>
      <button class="tbnStyle2" v-else>
        提取中
      </button>
    </div>

    <div class="StakeInfo">
      <div class="StakeResult">{{ $t("Withdraw.Earnings") }}:</div>
      <div class="StakeMoney">
        {{ formattedShareMoney }}<img class="daimi" :src="daimni" />
      </div>
    </div>
    <div class="StakeInfo">
      <div class="StakeResult">{{ $t("Withdraw.Brokerage") }}:</div>
      <div class="StakeMoney">
        {{ formattedTemeMoney }}<img class="daimi" :src="daimni" />
      </div>
    </div>

    <div class="StakeAuthor">
      <div class="Author">
        <button class="StakeBtn" @click="withdraw2" v-if="typeReinvestment==0">
          {{ $t("Withdraw.Reinvest") }}
        </button>
        <button class="StakeBtn" v-else>
          再资中
        </button>
      </div>
      <div class="Stakeing">
        <button class="StakeBtn" @click="withdraw3" v-if="typeReceive==0">
          {{ $t("Withdraw.Getreward") }}
        </button>
        <button class="StakeBtn" v-else>
          领取中
        </button>
      </div>
    </div>
    <div><img class="bStyle" :src="stylea" alt="" /></div>
  </div>
</template>

<style lang="less" scoped>
.stakewait {
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0.4px rgb(80, 77, 77) inset;
  background-image: linear-gradient(
    133deg,
    rgba(7, 110, 150, 0.3),
    /* Adjust the last value (0.2) to set the opacity of the first color */
      rgba(132, 2, 72, 0.6)
  );
  /* Adjust the last value (0.2) to set the opacity of the second color */
  box-sizing: border-box;
  width: 330px;
  color: white;
  padding: 40px 20px 20px 20px;

  .bStyle {
    width: 290px;
    height: 76px;
  }

  .StakeAuthor {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .StakeBtn {
      width: 136px;
      height: 43px;
      border-radius: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 18px;
      color: rgb(2, 0, 0);
      font-size: 20px;
      background-image: linear-gradient(
        126deg,
        rgba(142, 200, 243, 0.3),
        /* Adjust the last value (0.2) to set the opacity of the first color */
          rgba(250, 51, 158, 0.3)
      );
    }

    .Author {
      width: 136px;
      height: 43px;
    }

    .Stakeing {
      width: 136px;
      height: 43px;
    }
  }

  .StakeInfo {
    display: flex;
    // margin-bottom: 10px;

    .StakeResult {
      flex: 1;
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      top: 6px;
    }

    .StakeMoney {
      text-align: right;
      font-size: 20px;
      font-weight: 600;

      .daimi {
        margin-left: 5px;
        width: 14px;
        height: 12px;
      }
    }
  }

  .WithDrawBtn {
    .tbnStyle2 {
      width: 100%;
      margin-bottom: 15px;
      height: 50px;
      line-height: 50px;
      position: relative;
      color: rgb(9, 0, 0);
      font-weight: bolder;
      top: 8px;
      border: none;
      outline: none;
      font-size: 20px;
      border-radius: 8px;
      padding: 0 10px;
      cursor: pointer;
      background: url("../assets/img/Gradient.svg") no-repeat center
        center/cover;
    }
  }

  .inputInfo {
    border: 3px solid #81c0f1;
    height: 53px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 10px 0;
    display: flex;

    .inputStyle {
      border: none;
      outline: none;
      width: 220px;
      height: 100%;
      font-size: 20px;
      padding: 0 10px;
      color: #81c0f1;
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .tbnStyle {
      flex: 1;
      color: rgb(72, 2, 64);
      border: none;
      outline: none;
      font-size: 20px;
      border-radius: 0px 5px 5px 0px;
      cursor: pointer;
      background: url("../assets/img/Gradient.svg") no-repeat center
        center/cover;
    }
  }

  .ownwalletInfo {
    font-family: "Poppins", sans-serif;
    display: flex;

    .ownTips {
      font-size: 18px;
      font-weight: 600;
      flex: 1;
    }

    .ownMoney {
      font-size: 20px;

      img.daimi {
        width: 14px;
        height: 12px;
        margin-left: 5px;
      }
    }
  }

  .coinInfomation {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .coinImg {
      width: 29px;
      height: 29px;

      .dailogo {
        width: 29px;
        height: 29px;
      }
    }

    .coinInfo {
      font-size: 18px;
      font-family: "Poppins", sans-serif;
      position: relative;
      top: 0px;
      left: 10px;
      font-weight: bold;
    }
  }
}
</style>
<script>
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import siteapi from "@/json/siteapi.json";
// import Plugens from "@/plugins/index";
export default {
  name: "EvWithdraw",
  data() {
    return {
      dai: dai,
      daimni: daimni,
      daiValue: 0,
      daiOwnMoney: 0,
      daiShareMoney: 0,
      daiTemeMoney: 0.0,
      stylea: stylea,
      usersdata:{},
      typeextract:0,
      typeReinvestment:0,
      typeReceive:0
    };
  },
  async mounted() {
   // 判断是否连接钱包
   if (typeof window.ethereum !== 'undefined') {
  console.log("已连接钱包");

  // 异步获取网络信息
  window.ethereum
    .request({ method: 'eth_chainId' })
    .then((chainId) => {
      // 判断当前网络是否为Polygon链
      if (chainId === '0x89') {
        console.log("当前网络为Polygon链");
        // this.$router.push({path:'/EvStake'});
      } else {
        console.log("当前网络不是Polygon链");
        this.$router.push({path:'/'});
        return
      }
    })
    .catch((error) => {
      console.error('获取网络信息失败:', error);
    });
} else {
  console.log("未连接钱包");
}
this.sitedata=siteapi
    const currentAddress = await web3Utils.connectWallet();
    console.log(currentAddress)
    this.addresscode=currentAddress
    if (currentAddress) {
      // this.daiOwnMoney = parseFloat(
      //   await web3Utils.getDaiBalance(currentAddress)
      // );

      // console.log(this.daiOwnMoney.toFixed(6));
      this.users()
      this.getMyUserInterest()
      // this.exchangeallowance()
    }
  },
  methods: {
     // 用户信息
  async users(){
    console.log(this.sitedata)
    let _decimals = 18;
    let ContractAddress;
    console.log('用户信息')
    ContractAddress = this.sitedata.aave_token.address;
      await contract.users(
        this.addresscode,  //当前用户地址
        ContractAddress,  //调用合约
        _decimals
      ).then((res) => {
        console.log(res,'用户信息');
        this.usersdata=res.data
        this.daiOwnMoney=res.data.investAmount*1
        this.daiTemeMoney=res.data.rewardAmount*1
        // console.log(this.stakedMoney)
        // const total = 14000;
        // this.currentProgress = (this.stakedMoney / total) * 100;
      });
    },
      // 个人收益
  async getMyUserInterest(){
    console.log(this.sitedata)
    let _decimals = 18;
    let ContractAddress;
    console.log('个人收益')
    ContractAddress = this.sitedata.aave_token.address;
      await contract.getMyUserInterest(
        this.addresscode,  //当前用户地址
        ContractAddress,  //调用合约
        _decimals
      ).then((res) => {
        console.log(res,'个人收益');
        this.daiShareMoney=res.data*1
        console.log(this.daiShareMoney)
        // console.log(this.stakedMoney)
        // const total = 14000;
        // this.currentProgress = (this.stakedMoney / total) * 100;
      });
    },
    // 提取按钮
    withdraw() {
      if(this.daiValue<=0){

        this.$Notice.warning({
        title: "FlashMonkey",
        desc:  '请输入参数',
      });
        return
      }
      if(this.daiValue>this.daiOwnMoney){

       this.$Notice.warning({
       title: "FlashMonkey",
       desc:  '金额不足',
     });
       return
     }
      this.withdrawfun();
      // this.warning(false);
    },
        // 提取
  async withdrawfun() {
    console.log("提取")
    let _decimals = 18;
    let Address;
    Address = this.sitedata.aave_token.address;
      this.typeextract = 1;
    try {
      await contract.withdraw(
        Address, //合约地址
        this.daiValue, //数量
        this.addresscode,//当前地址
        _decimals,//小数

      ).then((res) => {
        console.log(res);
        this.typeextract = 0;
        if (res.code == 0) {
          this.typeextract = 0;
          this.$Notice.success({
            title: "提示",
            desc:  '提取成功',
          });
          this.users()
      this.getMyUserInterest()
        } else {
          // this.typeApprove = 1;
          this.$Notice.warning({
            title: "FlashMonkey",
            desc:  '提取失败',
          });
        }

      });
    } catch (error) {
      // this.typeApprove = 1;
      this.$Notice.warning({
        title: "FlashMonkey",
        desc:  '提取失败',
      });
      console.log(error);
    }
  },
    withdraw2() {
      var value=this.daiShareMoney+this.daiTemeMoney
      console.log(value)
      if(value >= 0){
        console.log(123)
        this.depositInterestAndReward()
      }else{
        this.$Notice.warning({
        title: "FlashMonkey",
        desc:  '金额不足',
      });
      }
      // this.warning2(false);
    },
       // 再资
  async depositInterestAndReward() {
    console.log("再资")
    let _decimals = 18;
    let Address;
    Address = this.sitedata.aave_token.address;
      this.typeReinvestment = 1;
    try {
      await contract.depositInterestAndReward(
        Address, //合约地址
        this.daiValue, //数量
        this.addresscode,//当前地址
        _decimals,//小数

      ).then((res) => {
        console.log(res);
        this.typeReinvestment = 0;
        if (res.code == 0) {
          this.typeReinvestment = 0;
          this.$Notice.success({
            title: "提示",
            desc:  '再资成功',
          });
          this.users()
      this.getMyUserInterest()
        } else {
          // this.typeApprove = 1;
          this.$Notice.warning({
            title: "FlashMonkey",
            desc:  '再资失败',
          });
        }

      });
    } catch (error) {
      // this.typeApprove = 1;
      this.$Notice.warning({
        title: "FlashMonkey",
        desc:  '再资失败',
      });
      console.log(error);
    }
  },
    withdraw3() {
      var value=this.daiShareMoney+this.daiTemeMoney
      console.log(value)
      if(value >= 0){
        this.withdrawInterestAndReward()
      }else{
        this.$Notice.warning({
        title: "FlashMonkey",
        desc:  '金额不足',
      });
      }
    },
        // 领取
  async withdrawInterestAndReward() {
    console.log("领取")
    let _decimals = 18;
    let Address;
    Address = this.sitedata.aave_token.address;
      this.typeReceive = 1;
    try {
      await contract.withdrawInterestAndReward(
        Address, //合约地址
        this.daiValue, //数量
        this.addresscode,//当前地址
        _decimals,//小数

      ).then((res) => {
        console.log(res);
        this.typeReceive = 0;
        if (res.code == 0) {
          this.typeReceive = 0;
          this.$Notice.success({
            title: "提示",
            desc:  '领取成功',
          });
          this.users()
      this.getMyUserInterest()
        } else {
          // this.typeApprove = 1;
          this.$Notice.warning({
            title: "FlashMonkey",
            desc:  '领取失败',
          });
        }

      });
    } catch (error) {
      // this.typeApprove = 1;
      this.$Notice.warning({
        title: "FlashMonkey",
        desc:  '领取失败',
      });
      console.log(error);
    }
  },
    warning(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.withdraw"),
      });
    },
    warning2(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.Reinvest"),
      });
    },
    warning3(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.Getreward"),
      });
    },
  },

  computed: {
    formattedDaiOwnMoney() {
      if (!this.daiOwnMoney) return "0.00";
      const multipliedNumber = this.daiOwnMoney * 100000;
const truncatedNumber = Math.trunc(multipliedNumber);
const result = truncatedNumber / 100000;
console.log(result)
console.log(this.daiOwnMoney)
      let value = parseFloat(result);
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 5,
        maximumFractionDigits: 5,
      });
    },
    formattedShareMoney() {
      if (!this.daiShareMoney) return "0.00";
      const multipliedNumber = this.daiShareMoney * 100000;
const truncatedNumber = Math.trunc(multipliedNumber);
const result = truncatedNumber / 100000;
console.log(result)
      let value = parseFloat(result);
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 5,
        maximumFractionDigits: 5,
      });
    },
    formattedTemeMoney() {
      if (!this.daiTemeMoney) return "0.00";
      const multipliedNumber = this.daiTemeMoney * 100000;
const truncatedNumber = Math.trunc(multipliedNumber);
const result = truncatedNumber / 100000;
console.log(result)
      let value = parseFloat(result);
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 5,
        maximumFractionDigits: 5,
      });
    },
  },
};
</script>
