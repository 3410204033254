const ja = {
  network: {
    tips: "選択されたネットワークは現在サポートされていません！Matic（Polygon）ネットワークを選択してください！",
    ev: `MetaMask、Trust Wallet、imToken、TokenPocketなどのウォレットソフトウェアのいずれかをインストールしているか確認してください。`,
    copyight: "FlashMonkey",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `投資者へ：この分散アプリケーション（DApp）には、不確実な投資リスクが伴います。各国の政策や規制は、ブロックチェーン技術と関連投資に対して厳格な制御や制限を設けている場合があります。さらに、仮想通貨の価格は非常に変動しやすく、投資者は大きな投資リスクを負う可能性があります。投資に参加する前に、投資者は投資リスクを十分に理解し評価し、慎重な判断を下すべきです。投資者が盲目的に投資を行
    い、取り返しのつかない経済的損失を避けるため、我々は冷静か
    つ理性的な投資を強くお勧めします。`,
    protocol: `この分散アプリケーション（DApp）には不確かな投資リスクが潜んでいます。使用する前に、リスク警告とプライバシーポリシーを必ず注意深く読んで理解してください。`,
    more: "詳細",
    media: "関連ソーシャルメディア",
    stake:
      "2023年11月6日に全世界公開、TelegramとTwitterをフォローしてください！",
    withdraw:
      "2023年11月6日に全世界公開、TelegramとTwitterをフォローしてください！",
    Reinvest:
      "2023年11月6日に全世界公開、TelegramとTwitterをフォローしてください！",
    Getreward:
      "2023年11月6日に全世界公開、TelegramとTwitterをフォローしてください！",
    linkwallet: "ウォレットに接続",
    notification: "FlashMonkey 2023/10/20 20:40 グローバルパブリックテスト開始",
  },
  leavel: {
    1: "ブラックカラント",
    2: "さくらんぼ",
    3: "ドラゴンフルーツ",
    4: "ヘーゼルナッツ",
    5: "ノニ",
    6: "柿",
    7: "トリュフ",
  },
  top: {
    Account: "アカウント",
  },
  stake: {
    Balance: "あなたの残高",
    Total: "総ステーク",
    Appoove: "承認",
    Stake: "ステーク",
  },
  menu: {
    Home: "ホーム",
    Language: "言語",
    Document: "ドキュメント",
    Contract: "契約",
    Watch: "ウォッチ",
    Coming: "近日公開",
    EvDao: "FM-DAO",
  },
  Withdraw: {
    Withdraw: "引き出す",
    Withdrawable: "引き出し可能",
    Earnings: "収益",
    Brokerage: "ブローカー額",
    Reinvest: "再投資",
    Getreward: "報酬を得る",
    Processing: "処理中",
    unsuccessfule: "失敗",
    successful: "成功！",
  },
  Team: {
    innver: "Innver",
    Teamstaked: "チームのステーク",
    leavel: "レベル",
    Team: "チーム",
    Bind: "関係をバインド",
    info: "システムは一時的にバインディングを停止しています。オープンを待ってください",
  },
  Share: {
    Invitelink: "招待リンク",
    Intips:
      "現在、Dappの招待状は利用できません。最新のコミュニティアップデートをお待ちください。",
    copyTitle: "招待リンク",
    copyText: "招待リンクのコピーに成功しました！",
    copyCommnad: "リンクをコピー",
  },
  Contract: {
    contractaddress: "契約アドレス",
    Copylink: "Polygonscan",
    CopylinkText: "polygonscanへ進む!",
  },
  Wathch: {
    watchonlywallet: "ウォッチオンリーウォレット",
    addwallet: "追加",
    add: "追加",
    Leavel: "レベル",
    share: "共有",
    fnOpen: "このウォレットは、この機能への参加がまだ招待されていません！",
    nullString: "有効なPolygon(matic)ウォレットアドレスを入力してください！",
  },
};
export default ja;
