const en = {
  network: {
    tips: `The selected network is not currently supported!
    Please select the Matic (Polygon) network!'`,
    ev: "Please check whether you have installed any wallet software such as MetaMask, Trust Wallet, imToken, or TokenPocket.",
    copyight: "FlashMonkey",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `Investor Notice: This Distributed Application (DApp) carries uncertain investment risks. National policies and regulatory laws might strictly control and limit blockchain technology and related investments. Additionally, the price of digital currencies is highly volatile, potentially causing investors to bear significant investment risks. Before participating in investment, investors should fully understand and assess the risks of investment, making decisions cautiously. We strongly advise investors to invest rationally and avoid following 
    the herd blindly, preventing irreversible 
    financial losses.`,
    protocol: `This Distributed Application (DApp) harbors uncertain investment risks. Before use, please ensure you read and fully understand the risk warnings and privacy policy.`,
    more: "More",
    media: "Related Social Media",
    stake:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    withdraw:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    Reinvest:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    Getreward:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    linkwallet: "Connect Wallet",
    notification: "FlashMonkey 2023/10/20 20:40 Global Public Test Begins",
  },
  leavel: {
    1: "Blackcurrant",
    2: "Cherries",
    3: "DragonFruit",
    4: "Hazelnut",
    5: "Noni",
    6: "Persimmon",
    7: "Truffle",
  },
  top: {
    Account: "Account",
  },
  stake: {
    Balance: "Your Balance",
    Total: "Total Stake",
    Appoove: "Approve",
    Stake: "Stake",
  },
  menu: {
    Home: "Home",
    Language: "Language",
    Document: "Document",
    Contract: "Contract",
    Watch: "Watch",
    Coming: "Coming Soon",
    EvDao: "FM-DAO",
  },
  Withdraw: {
    Withdraw: "Withdraw",
    Withdrawable: "Withdrawable",
    Earnings: "Earnings",
    Brokerage: "Brokerage amount",
    Reinvest: "Reinvest",
    Getreward: "Get Reward",
    Processing: "Processing",
    unsuccessfule: "Unsuccessfule",
    successful: "Successful!",
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Team staked",
    leavel: "Leavel",
    Team: "Team",
    Bind: "Bind Relation",
    info: "System temporarily paused binding, please wait for it to open.",
  },
  Share: {
    Invitelink: "Invite Link",
    Intips: `The Dapp invitations are not currently available. 
            Please wait for the latest comImunity updates.`,
    copyTitle: "Invitation link",
    copyText: "The invitation link has been successfully copied!",
    copyCommnad: "Copy Link",
  },
  Contract: {
    contractaddress: "Contract Address",
    Copylink: "Polygonscan",
    CopylinkText: "Go to polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Watch-only wallet",
    addwallet: "Add wallet",
    add: "Add",
    Leavel: "Leavel",
    share: "Share",
    fnOpen:
      "This wallet has not been invited to participate in this feature yet!！",
    nullString: "Please enter a valid Polygon(matic) wallet address!",
  },
};
export default en;
