const th = {
  network: {
    tips: `เครือข่ายที่เลือกไม่ได้รับการสนับสนุนในปัจจุบัน!
      กรุณาเลือกเครือข่าย Matic (Polygon)!`,
    ev: `โปรดตรวจสอบว่าคุณได้ติดตั้งซอฟต์แวร์กระเป๋าเงินอย่าง MetaMask, 
    Trust Wallet, imToken, หรือ TokenPocket หรือไม่`,
    copyight: "FlashMonkey",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `ข้อควรทราบสำหรับนักลงทุน: แอปพลิเคชันที่กระจายอำนาจ (DApp) นี้มีความเสี่ยงในการลงทุนที่ไม่แน่นอน นโยบายและกฎระเบียบของแต่ละประเทศอาจมีการควบคุมและจำกัดเกี่ยวกับเทคโนโลยีบล็อกเชนและการลงทุนที่เกี่ยวข้องอย่างเข้มงวด นอกจากนี้ยังมีความผันผวนสูงของราคาสกุลเงินดิจิทัล ทำให้นักลงทุนต้องเผชิญกับความเสี่ยงในการลงทุนที่มาก นักลงทุนควรทำความเข้าใจและประเมินความเสี่ยงในการลงทุนให้ดีก่อนที่จะลงทุน และต้องตัดสินใจอย่างระมัดระวัง พวกเราขอแนะนำให้นักลงทุนลงทุนอย่างมีเหตุผลและไม่ตามกระแส 
    จึงจะหลีกเลี่ยงการสูญเสียทางเศรษฐกิจที่ไม่สามารถกู้คืนได้`,
    protocol: `แอปพลิเคชันที่กระจายอำนาจ (DApp) นี้มีความเสี่ยงในการลงทุนที่ไม่แน่นอน ก่อนใช้งาน โปรดอ่านและทำความเข้าใจในคำเตือนเกี่ยวกับความเสี่ยงและนโยบายความเป็นส่วนตัวอย่างรอบคอบ`,
    more: "รายละเอียด",
    media: "โซเชียลมีเดียที่เกี่ยวข้อง",
    stake:
      "เปิดทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราทาง Telegram และ Twitter!",
    withdraw:
      "เปิดทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราทาง Telegram และ Twitter!",
    Reinvest:
      "เปิดทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราทาง Telegram และ Twitter!",
    Getreward:
      "เปิดทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราทาง Telegram และ Twitter!",
    linkwallet: "เชื่อมต่อกระเป๋าเงิน",
    notification: "FlashMonkey 2023/10/20 20:40 เริ่มการทดสอบสาธารณะระดับโลก",
  },
  leavel: {
    1: "แบล็คเคอร์แรนท์",
    2: "เชอร์รี",
    3: "แก้วมังกร",
    4: "เฮเซิลนัท",
    5: "โนนิ",
    6: "ลูกพลับ",
    7: "ทรัฟเฟิล",
  },
  top: {
    Account: "บัญชี",
  },
  stake: {
    Balance: "ยอดคงเหลือของคุณ",
    Total: "เดิมพันทั้งหมด",
    Appoove: "อนุมัติ",
    Stake: "เดิมพัน",
  },
  menu: {
    Home: "หน้าแรก",
    Language: "ภาษา",
    Document: "เอกสาร",
    Contract: "สัญญา",
    Watch: "ดู",
    Coming: "เร็ว ๆ นี้",
    EvDao: "FM-DAO",
  },
  Withdraw: {
    Withdraw: "ถอน",
    Withdrawable: "สามารถถอนได้",
    Earnings: "รายได้",
    Brokerage: "จำนวนค่านายหน้า",
    Reinvest: "ลงทุนใหม่",
    Getreward: "รับรางวัล",
    Processing: "กำลังดำเนินการ",
    unsuccessfule: "ไม่ประสบความสำเร็จ",
    successful: "ประสบความสำเร็จ!",
  },
  Team: {
    innver: "Innver",
    Teamstaked: "ทีมเดิมพัน",
    leavel: "ระดับ",
    Team: "ทีม",
    Bind: "ผูกความสัมพันธ์",
    info: "ระบบพักชั่วคราวการผูกข้อมูล โปรดรอให้เปิด！",
  },
  Share: {
    Invitelink: "ลิงก์เชิญ",
    Intips:
      "คำเชิญ Dapp ไม่สามารถใช้ได้ในปัจจุบัน โปรดรอการอัปเดตล่าสุดจากชุมชน",
    copyTitle: "ลิงค์เชิญ",
    copyText: "คัดลอกลิงค์เชิญสำเร็จแล้ว!",
    copyCommnad: "คัดลอกลิงค์",
  },
  Contract: {
    contractaddress: "ที่อยู่สัญญา",
    Copylink: "Polygonscan",
    CopylinkText: "ไปที่ polygonscan!",
  },
  Wathch: {
    watchonlywallet: "กระเป๋าสตางค์ดูเท่านั้น",
    addwallet: "เพิ่มกระเป๋าสตางค์ดูเท่านั้น",
    add: "เพิ่ม",
    Leavel: "ระดับ",
    share: "แบ่งปัน",
    fnOpen: "ยังไม่ได้เชิญกระเป๋าเงินนี้เข้าร่วมฟีเจอร์นี้!",
    nullString: "โปรดใส่ที่อยู่กระเป๋าเงิน Polygon(matic) ที่ถูกต้อง!",
  },
};
export default th;
