import Web3 from "web3";
import big from "big.js";
// import { utils } from "ethers";
// tb合约pg合约方法
export default {
    // 用户信息
  async users(
    currentAddress,
    ContractAddress,
    _decimals) {
    try {
    //   let position = "";
    console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/polygon.json");
        const ethereum = window.ethereum;
        const web3 = new Web3(Web3.givenProvider || ethereum);
        // 创建合约对象
        console.log(Factory.abi)
        const tokebContract = new web3.eth.Contract(
            Factory.abi,
            ContractAddress
          );
          let datas=0
          console.log(tokebContract,'地址')
            const parameter= await tokebContract.methods
            .users(currentAddress)
            .call();

         datas = parameter;

      datas.investAmount = big(datas.investAmount)
      .div(10 ** 18)
      .toFixed();
      datas.rewardAmount = big(datas.rewardAmount)
      .div(10 ** 18)
      .toFixed();

      datas.teamInvestAmount = big(datas.teamInvestAmount)
      .div(10 ** 18)
      .toFixed();
      // datas.level = big(datas.level)
      // .div(10 ** 18)
      // .toFixed();
      return { code:"0",data:datas};

    } catch (error) {
      console.log(error);
    }
  },
   // 已投资
   async allInvestAmount(
    currentAddress,
    ContractAddress,
    _decimals) {
    try {
    //   let position = "";
    console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/polygon.json");
        const ethereum = window.ethereum;
        const web3 = new Web3(Web3.givenProvider || ethereum);
        // 创建合约对象
        console.log(Factory.abi)
        const tokebContract = new web3.eth.Contract(
            Factory.abi,
            ContractAddress
          );
          let datas=0
          console.log(tokebContract,'地址')
            const parameter= await tokebContract.methods
            .allInvestAmount(currentAddress)
            .call();

         datas = parameter;

      datas = big(datas)
      .div(10 ** 18)
      .toFixed();
      // datas.level = big(datas.level)
      // .div(10 ** 18)
      // .toFixed();
      return { code:"0",data:datas};

    } catch (error) {
      console.log(error);
    }
  },
  // 需要投资
  async limitAllInvestAmount(
    currentAddress,
    ContractAddress,
    _decimals) {
    try {
    //   let position = "";
    console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/polygon.json");
        const ethereum = window.ethereum;
        const web3 = new Web3(Web3.givenProvider || ethereum);
        // 创建合约对象
        console.log(Factory.abi)
        const tokebContract = new web3.eth.Contract(
            Factory.abi,
            ContractAddress
          );
          let datas=0
          console.log(tokebContract,'地址')
            const parameter= await tokebContract.methods
            .limitAllInvestAmount(currentAddress)
            .call();

         datas = parameter;

         datas = big(datas)
         .div(10 ** 18)
         .toFixed();
      return { code:"0",data:datas};

    } catch (error) {
      console.log(error);
    }
  },
  // 最小投入总数
  async minInvestAmount(
    currentAddress,
    ContractAddress,
    _decimals) {
    try {
    //   let position = "";
    console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/polygon.json");
        const ethereum = window.ethereum;
        const web3 = new Web3(Web3.givenProvider || ethereum);
        // 创建合约对象
        console.log(Factory.abi)
        const tokebContract = new web3.eth.Contract(
            Factory.abi,
            ContractAddress
          );
          let datas=0
          console.log(tokebContract,'地址')
            const parameter= await tokebContract.methods
            .minInvestAmount(currentAddress)
            .call();

         datas = parameter;

         datas = big(datas)
         .div(10 ** 18)
         .toFixed();
      return { code:"0",data:datas};

    } catch (error) {
      console.log(error);
    }
  },
   // 个人收益

   async getMyUserInterest(
    currentAddress,
    ContractAddress,
    _decimals) {
    try {
    //   let position = "";
    console.log(_decimals)
      let Factory;
      Factory = require("../json/pancakeswap/polygon.json");
        const ethereum = window.ethereum;
        const web3 = new Web3(Web3.givenProvider || ethereum);
        // 创建合约对象
        console.log(Factory.abi)
        const tokebContract = new web3.eth.Contract(
            Factory.abi,
            ContractAddress
          );
          let datas=0
          console.log(tokebContract,'地址')
            const parameter= await tokebContract.methods
            .getMyUserInterest(currentAddress)
            .call();

         datas = parameter;
         datas = big(datas)
         .div(10 ** 18)
         .toFixed();

      return { code:"0",data:datas};

    } catch (error) {
      console.log(error);
    }
  },
    // 存入
    async deposit(
      tokenAddress,
      amountADesired,
      CurrentAccount,
      _decimals,
      ) {
      try {
      //   let position = "";
      console.log(_decimals)
        let Factory;
        Factory = require("../json/pancakeswap/polygon.json");
          const ethereum = window.ethereum;
          const web3 = new Web3(Web3.givenProvider || ethereum);
          // 创建合约对象
          console.log(Factory.abi)


      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();
        console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
      console.log(tokenAddress)
console.log(tokebContract)
      const approveFunction = tokebContract.methods.deposit(amountApproved); // 替换为您要授权的代币数量
      console.log(approveFunction)
    //  预估
    // alert("预估:"+JSON.stringify(approveFunction));
    // alert("预估2:"+JSON.stringify({ from: CurrentAccount,to:tokenAddress,data:approveFunction.encodeABI(), }));

    //   alert("预估");
    // return approveFunction.estimateGas({ from: CurrentAccount,to:tokenAddress,data:approveFunction.encodeABI()})
    // .then((res) => {
    //   alert("成功");
    //   console.log('Approval successful');
    //   console.log(res)
    //   return asd(res)
    // })
    // ?.catch((error) => {
    //   alert("1");
    //   console.log('Approval error:', error);
    //   alert("2");
    //   return { code: "3" };
    // });
    // function asd(hexValue){
    //   return tokebContract.methods
    //     .deposit(amountApproved)
    //     .send({ from: CurrentAccount,to:tokenAddress,data:approveFunction.encodeABI(), gasLimit: hexValue })
    //     .then(()=>{
    //       console.log('Approval successful');
    //       alert("3");
    //       return { code:"0"};
    //     })
    //     .catch((error) => {
    //       alert("4");
    //       console.log('Approval error:', error);
    //       return { code: "1" };
    //     });
    // }
      const hexValue = await approveFunction.estimateGas({ from: CurrentAccount,to:tokenAddress,data:approveFunction.encodeABI() });
      console.log(hexValue);

      await tokebContract.methods
        .deposit(amountApproved)
        .send({ from: CurrentAccount,to:tokenAddress,data:approveFunction.encodeABI(), gasLimit: hexValue,gas:hexValue,gasPrice:300000000000  });

        return { code:"0"};
      } catch (error) {
        console.log(error);
        return { code: "1" };
      }
    },
        // 提取
        async withdraw(
          tokenAddress,
          amountADesired,
          CurrentAccount,
          _decimals,
          ) {
          try {
          //   let position = "";
          console.log(_decimals)
            let Factory;
            Factory = require("../json/pancakeswap/polygon.json");
              const ethereum = window.ethereum;
              const web3 = new Web3(Web3.givenProvider || ethereum);
              // 创建合约对象
              console.log(Factory.abi)

          const amountApproved = big(amountADesired)
            .times(10 ** _decimals)
            .toFixed();
            console.log(amountApproved)
          const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
          console.log(tokenAddress)
    console.log(tokebContract)
          const approveFunction = tokebContract.methods.withdraw(amountApproved); // 替换为您要授权的代币数量
          console.log(approveFunction)
        //  预估
          const hexValue = await approveFunction.estimateGas({ from: CurrentAccount,to:tokenAddress,data:approveFunction.encodeABI() });
          console.log(hexValue);

          await tokebContract.methods
            .withdraw(amountApproved)
            .send({ from: CurrentAccount, gasLimit: hexValue,to:tokenAddress,data:approveFunction.encodeABI(),gas:hexValue,gasPrice:300000000000 });

            return { code:"0"};
          } catch (error) {
            console.log(error);
          }
        },
        // 再资
        async depositInterestAndReward(
          tokenAddress,
          amountADesired,
          CurrentAccount,
          _decimals,
          ) {
          try {
          //   let position = "";
          console.log(_decimals)
            let Factory;
            Factory = require("../json/pancakeswap/polygon.json");
              const ethereum = window.ethereum;
              const web3 = new Web3(Web3.givenProvider || ethereum);
              // 创建合约对象
              console.log(Factory.abi)

          const amountApproved = big(amountADesired)
            .times(10 ** _decimals)
            .toFixed();
            console.log(amountApproved)
          const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
          console.log(tokenAddress)
    console.log(tokebContract)
          const approveFunction = tokebContract.methods.depositInterestAndReward(); // 替换为您要授权的代币数量
          console.log(approveFunction)
        //  预估
          const hexValue = await approveFunction.estimateGas({ from: CurrentAccount,to:tokenAddress,data:approveFunction.encodeABI() });
          console.log(hexValue);

          await tokebContract.methods
            .depositInterestAndReward()
            .send({ from: CurrentAccount, gasLimit: hexValue,to:tokenAddress,data:approveFunction.encodeABI(),gas:hexValue,gasPrice:300000000000 });

            return { code:"0"};
          } catch (error) {
            console.log(error);
          }
        },
        // 领取
        async withdrawInterestAndReward(
          tokenAddress,
          amountADesired,
          CurrentAccount,
          _decimals,
          ) {
          try {
          //   let position = "";
          console.log(_decimals)
            let Factory;
            Factory = require("../json/pancakeswap/polygon.json");
              const ethereum = window.ethereum;
              const web3 = new Web3(Web3.givenProvider || ethereum);
              // 创建合约对象
              console.log(Factory.abi)

          const amountApproved = big(amountADesired)
            .times(10 ** _decimals)
            .toFixed();
            console.log(amountApproved)
          const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
          console.log(tokenAddress)
    console.log(tokebContract)
          const approveFunction = tokebContract.methods.withdrawInterestAndReward(); // 替换为您要授权的代币数量
          console.log(approveFunction)
        //  预估
          const hexValue = await approveFunction.estimateGas({ from: CurrentAccount ,to:tokenAddress,data:approveFunction.encodeABI()});
          console.log(hexValue);

          await tokebContract.methods
            .withdrawInterestAndReward()
            .send({ from: CurrentAccount, gasLimit: hexValue,to:tokenAddress,data:approveFunction.encodeABI(),gas:hexValue,gasPrice:300000000000 });

            return { code:"0"};
          } catch (error) {
            console.log(error);
          }
        },
    // 绑定
    async bind(
      tokenAddress,
      amountADesired,
      CurrentAccount,
      _decimals,
      ) {
      try {
      //   let position = "";
      console.log(_decimals)
        let Factory;
        Factory = require("../json/pancakeswap/polygon.json");
          const ethereum = window.ethereum;
          const web3 = new Web3(Web3.givenProvider || ethereum);
          // 创建合约对象
          console.log(Factory.abi)
      //     const number = amountADesired;
      //     const roundedNumber = Math.floor(number * 100000) / 100000 - 0.00001;

      //     console.log(roundedNumber); // 输出 10.4324

      // const amountApproved = big(roundedNumber)
      //   .times(10 ** _decimals)
      //   .toFixed();
      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);
      console.log(tokenAddress)
console.log(tokebContract)
      const approveFunction = tokebContract.methods.bind(amountADesired); // 替换为您要授权的代币数量
      console.log(approveFunction)
    //  预估
      const hexValue = await approveFunction.estimateGas({ from: CurrentAccount ,to:tokenAddress,data:approveFunction.encodeABI()});
      console.log(hexValue);

      await tokebContract.methods
        .bind(amountADesired)
        .send({ from: CurrentAccount, gasLimit: hexValue ,to:tokenAddress,data:approveFunction.encodeABI(),gas:hexValue,gasPrice:300000000000});

        return { code:"0"};
      } catch (error) {
        console.log(error);
      }
    },

};
