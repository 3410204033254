<template>
  <div>
    <!-- v-show="isBind" -->
    <div  class="stakewait">
      <div class="coinInfomation">
        <div class="coinInfo">
          {{ $t("Team.Teamstaked") }} <img :src="daimni" class="coinImg" />
        </div>
      </div>
      <div class="coinInfomation">
        <div class="coinInfo2">
          {{usersdata.teamInvestAmount}}
          <!-- <counts
            :start="0"
            :end="parseFloat(this.formattedDaiOwnMoney.replace(/,/g, ''))"
            :duration="1000"
          /> -->
        </div>
      </div>

      <Divider style="background: rgba(142, 200, 243, 0.1)" />

      <div class="inputInfoOk" v-show="isBind">
        <!-- <input v-model.number="daiValue" type="text" class="inputStyle"> -->
        {{ $t("Team.innver") }}: {{ shortinnverAddress }}
      </div>
      <div class="inputInfo" v-show="isBind == false">
        <input placeholder="请输入地址" v-model="quer" type="text" class="inputStyle" />
      </div>
      <div class="StakeInfo">
        <div class="StakeResult">
          <img :src="honor" alt="" />{{ $t("Team.leavel") }} : {{ honorLeave }}
        </div>
        <div class="StakeMoney">
          <img :src="link" alt="" /> {{ $t("Team.Team") }} : {{ usersdata.teamCount }}
        </div>
      </div>
      <div class="StakeAuthor " v-show="isBind == false">
        <button class="StakeBtn2" @click="bindrel()" v-if="typebind==0">
          {{ $t("Team.Bind") }}
        </button>
        <button class="StakeBtn2" v-else>
         绑定中
        </button>
      </div>
      <!-- <div class="StakeAuthor">
            <button class="StakeBtn2">{{ $t('Team.Bind') }}</button>
        </div> -->
      <div><img class="bStyle" :src="stylea" alt="" /></div>
    </div>

    <!-- <div v-show="isBind == false" class="stakewait">
      <div class="coinInfomation">
        <div class="coinInfo">{{ $t("Team.Teamstaked") }}</div>
      </div>

      <Divider style="background: rgba(142, 200, 243, 0.1)" />

      <div class="inputInfo">
        <input v-model="quer" type="text" class="inputStyle" />
      </div>
      <div class="StakeAuthor maginTop20">
        <button class="StakeBtn2" @click="bindrel()" v-if="typebind==0">
          {{ $t("Team.Bind") }}
        </button>
        <button class="StakeBtn2" v-else>
         绑定中
        </button>
      </div>
      <div><img class="bStyle" :src="stylea" alt="" /></div>
    </div> -->
  </div>
</template>

<style lang="less" scoped>
.stakewait {
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0.4px rgb(80, 77, 77) inset;
  background-image: linear-gradient(
    133deg,
    rgba(7, 110, 150, 0.3),
    /* Adjust the last value (0.2) to set the opacity of the first color */
      rgba(132, 2, 72, 0.6)
  );
  /* Adjust the last value (0.2) to set the opacity of the second color */
  box-sizing: border-box;
  width: 330px;
  color: white;
  padding: 40px 20px 20px 20px;

  .bStyle {
    width: 290px;
    height: 76px;
  }

  .maginTop20 {
    margin-top: 20px;
  }

  .StakeAuthor {
    justify-content: space-between;

    .StakeBtn2 {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 18px;
      color: rgb(2, 0, 0);
      font-size: 20px;
      background-image: linear-gradient(
        126deg,
        rgba(142, 200, 243, 0.3),
        /* Adjust the last value (0.2) to set the opacity of the first color */
          rgba(250, 51, 158, 0.3)
      );
    }

    .Author {
      width: 136px;
      height: 43px;
    }

    .Stakeing {
      width: 136px;
      height: 43px;
    }
  }

  .StakeInfo {
    display: flex;
    margin-bottom: 20px;

    .StakeResult {
      flex: 1;
      text-align: left;
      font-size: 14px;
      font-weight: 600;

      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 5px;
        margin-right: 10px;
      }
    }

    .StakeMoney {
      text-align: right;
      font-size: 14px;
      font-weight: 600;

      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 5px;
        margin-right: 10px;
      }

      .daimi {
        margin-left: 5px;
        width: 14px;
        height: 12px;
      }
    }
  }

  .inputInfo {
    border: 3px solid #81c0f1;
    height: 53px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 10px 0;
    display: flex;

    .inputStyle {
      border: none;
      outline: none;
      flex: 1;
      height: 100%;
      font-size: 11px;
      padding: 0 10px;
      color: #81c0f1;
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .tbnStyle {
      height: 40px;
      position: relative;
      color: white;
      top: 8px;
      right: 16px;
      border: none;
      outline: none;
      font-size: 20px;
      border-radius: 12px;
      padding: 0 10px;
      cursor: pointer;
      background: url("../assets/img/Gradient.svg") no-repeat center
        center/cover;
    }
  }

  .ownwalletInfo {
    font-family: "Poppins", sans-serif;
    display: flex;

    .ownTips {
      font-size: 18px;
      font-weight: 600;
      flex: 1;
    }

    .ownMoney {
      font-size: 20px;

      img.daimi {
        width: 14px;
        height: 12px;
        margin-left: 5px;
      }
    }
  }

  .inputInfoOk {
    width: 100%;
    border: 3px solid #86c6f8;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 15px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 20px;
  }

  .coinInfomation {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .coinImg {
      width: 18px;
      height: 18px;
      position: relative;
      top: 3px;
      left: 5px;

      .dailogo {
        width: 29px;
        height: 29px;
      }
    }

    .coinInfo {
      font-size: 18px;
      font-family: "Poppins", sans-serif;
      position: relative;
      top: 0px;
      font-weight: bold;
    }

    .coinInfo2 {
      font-size: 28px;
      font-family: "Poppins", sans-serif;
      position: relative;
      top: 0px;
      font-weight: bold;

      img {
        width: 20px;
        height: 20px;
        position: relative;
        left: 5px;
      }
    }
  }
}
</style>
<script>
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import link from "@/assets/img/link.svg";
import honor from "@/assets/img/honor.svg";
// import counts from "@/components/VueCount.vue";
import contract from "@/plugins/contract.js";
import siteapi from "@/json/siteapi.json";
export default {
  data() {
    return {
      isBind: false,
      link: link,
      honor: honor,
      dai: dai,
      daimni: daimni,
      daiValue: 0,
      daiOwnMoney: 0.0,
      stylea: stylea,
      quer: this.$route.query.ref,
      honorLeave: '',
      linkMember: 0,
      innverAddress: "0xD83A4ee63372B96041BcdfE7348E21f156BDb0A5",
      typebind:0,
      usersdata:{
        teamCount:"",
        teamInvestAmount:0.00
      }
    };
  },
  components: {
    // counts,
  },
  computed: {
    shortinnverAddress: {
      get() {
        if (this.innverAddress === "") {
          return "0x";
        } else {
          return (
            this.innverAddress.slice(0, 4) + "……" + this.innverAddress.slice(-8)
          );
        }
      },
      set(newVal) {
        this.innverAddress = newVal;
      },
    },
    shortQuerAddress: {
      get() {
        if (this.quer === "") {
          return "";
        } else {
          return this.quer.slice(0, 10) + "……" + this.quer.slice(-9);
        }
      },
      set(newVal) {
        this.innverAddress = newVal;
      },
    },
    formattedDaiOwnMoney() {
      if (!this.daiOwnMoney) return "0.00";
      let value = parseFloat(this.daiOwnMoney.toFixed(2));
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
  async mounted() {
   // 判断是否连接钱包
   if (typeof window.ethereum !== 'undefined') {
  console.log("已连接钱包");

  // 异步获取网络信息
  window.ethereum
    .request({ method: 'eth_chainId' })
    .then((chainId) => {
      // 判断当前网络是否为Polygon链
      if (chainId === '0x89') {
        console.log("当前网络为Polygon链");
        // this.$router.push({path:'/EvStake'});
      } else {
        console.log("当前网络不是Polygon链");
        this.$router.push({path:'/'});
        return
      }
    })
    .catch((error) => {
      console.error('获取网络信息失败:', error);
    });
} else {
  console.log("未连接钱包");
}
this.sitedata=siteapi
    const currentAddress = await web3Utils.connectWallet();
    console.log(currentAddress)
    this.addresscode=currentAddress
  //   var observeAddress= window.localStorage.getItem('observeAddress')
  //  if(observeAddress){
  //   this.addresscode=observeAddress
  //  }else{
  //   this.addresscode=currentAddress
  //  }
    if (currentAddress) {
      // this.daiOwnMoney = parseFloat(
      //   await web3Utils.getDaiBalance(currentAddress)
      // );
     
      // console.log(this.daiOwnMoney.toFixed(6));
      this.users()
      // this.getMyUserInterest()
      // this.exchangeallowance()
    }
  },
  methods: {
      // 用户信息
  async users(){
    console.log(this.sitedata)
    let _decimals = 18;
    let ContractAddress;
    console.log('用户信息')
    ContractAddress = this.sitedata.aave_token.address;
      await contract.users(
        this.addresscode,  //当前用户地址
        ContractAddress,  //调用合约
        _decimals
      ).then((res) => {
        console.log(res,'用户信息');
        if(res.data.referrer=='0x0000000000000000000000000000000000000000'){
          this.isBind=false
        }else{
          this.isBind=true
        }
        this.innverAddress=res.data.referrer
        if(res.data.level==0){
          this.honorLeave='none'
        }else{
          this.honorLeave=this.$t("leavel."+res.data.level)
        }
        
        this.usersdata=res.data
        this.usersdata.teamInvestAmount=(this.usersdata.teamInvestAmount*1).toFixed(2)
        // this.daiOwnMoney=res.data.investAmount*1
        // this.daiTemeMoney=res.data.rewardAmount*1
        // console.log(this.stakedMoney)
        // const total = 14000;
        // this.currentProgress = (this.stakedMoney / total) * 100;
      });
    },
    warning(nodesc) {
      this.$Notice.warning({
        title: this.$t("Team.Teamstaked"),
        desc: nodesc ? "" : this.$t("Team.info"),
      });
    },
    bindrel() {
      console.log(this.quer)
      if(this.quer==undefined||this.quer==''){
        this.$Notice.warning({
       title: "FlashMonkey",
       desc:  '请先输入地址',
     });
      }else{
        this.bind()
      }
      // this.warning(false);
    },
       // 绑定
  async bind() {
    console.log("绑定")
    let _decimals = 18;
    let Address;
    Address = this.sitedata.aave_token.address;
      this.typebind = 1;
    try {
      await contract.bind(
        Address, //合约地址
        this.quer, //绑定地址
        this.addresscode,//当前地址
        _decimals,//小数
        
      ).then((res) => {
        console.log(res);
        this.typebind = 0;
        if (res.code == 0) {
          this.typebind = 0;
          this.$Notice.success({
            title: "提示",
            desc:  '绑定成功',
          });
          this.users()
      // this.getMyUserInterest()
        } else {
          // this.typeApprove = 1;
          this.$Notice.warning({
            title: "FlashMonkey",
            desc:  '绑定失败',
          });
        }

      });
    } catch (error) {
      // this.typeApprove = 1;
      this.$Notice.warning({
        title: "FlashMonkey",
        desc:  '绑定失败',
      });
      console.log(error);
    }
  },
  },
  
};
</script>
