const de = {
  network: {
    tips: `Das ausgewählte Netzwerk wird derzeit nicht unterstützt!
    Bitte wählen Sie das Matic (Polygon) Netzwerk!`,
    ev: "Bitte überprüfen Sie, ob Sie Wallet-Software wie MetaMask, Trust Wallet, imToken oder TokenPocket installiert haben.",
    copyight: "FlashMonkey",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `Anlegerhinweis: Diese dezentrale Anwendung (DApp) birgt ungewisse Anlagerisiken. Nationale Richtlinien und gesetzliche Vorschriften könnten Blockchain-Technologie und damit verbundene Anlagen streng kontrollieren und beschränken. Außerdem sind die Preise von digitalen Währungen hochvolatil, was erhebliche Anlagerisiken für die Anleger mit sich bringt. Anleger sollten vor der Anlagebeteiligung die Risiken vollständig verstehen und bewerten und vorsichtig entscheiden. Wir raten dringend dazu, vernünftig zu investieren und nicht blind der Masse zu folgen, um irreversible finanzielle Verluste zu vermeiden.`,
    protocol: `Diese dezentrale Anwendung (DApp) birgt ungewisse Anlagerisiken. Bitte lesen und verstehen Sie vor der Benutzung die Risikowarnungen und Datenschutzrichtlinien gründlich.`,
    more: "Mehr",
    media: "Verwandte soziale Medien",
    stake:
      "Weltweite Eröffnung am 6. November 2023, folgen Sie uns auf Telegram und Twitter!",
    withdraw:
      "Weltweite Eröffnung am 6. November 2023, folgen Sie uns auf Telegram und Twitter!",
    Reinvest:
      "Weltweite Eröffnung am 6. November 2023, folgen Sie uns auf Telegram und Twitter!",
    Getreward:
      "Weltweite Eröffnung am 6. November 2023, folgen Sie uns auf Telegram und Twitter!",
    notification:
      "FlashMonkey 2023/10/20 20:40 Beginn des globalen öffentlichen Tests",
  },
  leavel: {
    1: "Blackcurrant",
    2: "Kirschen",
    3: "Drachenfrucht",
    4: "Haselnuss",
    5: "Noni",
    6: "Kaki",
    7: "Trüffel",
  },
  top: {
    Account: "Konto",
  },
  stake: {
    Balance: "Ihr Guthaben",
    Total: "Gesamt-Stake",
    Appoove: "Genehmigen",
    Stake: "Stake",
  },
  menu: {
    Home: "Startseite",
    Language: "Sprache",
    Document: "Dokument",
    Contract: "Vertrag",
    Watch: "Beobachten",
    Coming: "Demnächst",
    EvDao: "FM-DAO",
  },
  Withdraw: {
    Withdraw: "Abheben",
    Withdrawable: "Abhebbar",
    Earnings: "Erträge",
    Brokerage: "Maklergebühr",
    Reinvest: "Reinvestieren",
    Getreward: "Belohnung erhalten",
    Processing: "In Bearbeitung",
    unsuccessfule: "Erfolglos",
    successful: "Erfolgreich!",
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Team-Stake",
    leavel: "Stufe",
    Team: "Team",
    Bind: "Verbindung",
    info: "Das System hat vorübergehend die Bindung angehalten. Bitte warten Sie auf die Öffnung",
  },
  Share: {
    Invitelink: "Einladungslink",
    Intips: `Dapp-Einladungen sind derzeit nicht verfügbar. 
            Bitte warten Sie auf die neuesten Community-Updates.`,
    copyTitle: "Einladungslink",
    copyText: "Der Einladungslink wurde erfolgreich kopiert!",
    copyCommnad: "Link kopieren",
  },
  Contract: {
    contractaddress: "Vertragsadresse",
    Copylink: "Polygonscan",
    CopylinkText: "Gehe zu polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Wallet nur beobachten",
    addwallet: "Wallet hinzufügen",
    add: "Hinzufügen",
    Leavel: "Stufe",
    share: "Teilen",
    fnOpen:
      "Dieses Wallet wurde noch nicht zur Teilnahme an dieser Funktion eingeladen!",
    nullString:
      "Bitte geben Sie eine gültige Polygon(matic) Wallet-Adresse ein!",
  },
};
export default de;
